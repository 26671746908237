import { useTranslation } from "react-i18next";
import { settingsService } from "src/services/settings.service";
import classes from "./Headers.module.scss";
import { Link } from "react-router-dom";

export default function Header(): JSX.Element {
  const { settings } = settingsService();

  const { t } = useTranslation();

  return (
    <div className={classes.Header}>
      <div>
        <div className={classes.SiteIcon}></div>
        <Link to="/" className={classes.SiteLink}>
          <span>{settings.clientName}</span>
          <span>{t("public.header.onlineForms")}</span>
        </Link>
      </div>

      <div className={classes.HeaderLinkWrapper}>
        {settings.linkCadOnlineProtest && (
          <a
            target="_blank"
            rel="noreferrer"
            href={settings.onlinePortalURL}
            className={classes.HeaderLinks}
          >
            <div className={[classes.Icon, classes.OpIcon].join(" ")}></div>
            <div>{t("public.header.onlinePortal")}</div>
          </a>
        )}

        {settings.linkCadPropertySearch && (
          <a
            target="_blank"
            rel="noreferrer"
            href={settings.linkCadPropertySearch}
            className={classes.HeaderLinks}
          >
            <div className={[classes.Icon, classes.PsIcon].join(" ")}></div>
            <div>{t("public.header.propertySearch")}</div>
          </a>
        )}
        {settings.linkCadGisMap && (
          <a
            target="_blank"
            rel="noreferrer"
            href={settings.linkCadGisMap}
            className={classes.HeaderLinks}
          >
            <div className={[classes.Icon, classes.MapIcon].join(" ")}></div>
            <div>{t("public.header.interactiveMap")}</div>
          </a>
        )}

        {settings.linkCadWebsite && (
          <a
            target="_blank"
            rel="noreferrer"
            href={settings.linkCadWebsite}
            className={classes.HeaderLinks}
          >
            <div className={[classes.Icon, classes.Seal].join(" ")}></div>
            <div>{t("public.header.homepage")}</div>
          </a>
        )}

        <div className={classes.Home}>
          <a
            target="_blank"
            rel="noreferrer"
            href={settings.linkCadWebsite}
            className={classes.HomeLink}
          >
            {t("public.header.home")}
          </a>
        </div>
      </div>
    </div>
  );
}
